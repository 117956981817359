import React, { useState, useEffect } from 'react';
import Button from '../../../common/Button';
import { post } from '../../../services/api';
import CustomRadio from '../../../common/Radio/CustomRadio';
import './Poll.css';

const styles = {
  label: {
    textAlign: 'left',
    color: '#a9aaab',
    marginBottom: 5,
  },
  question: {
    fontSize: 18,
    textAlign: 'left',
  },
  list: {
    margin: '30px 0 20px 0',
  },
  button: {
    margin: '0 0 10px 0',
    width: '100%',
    padding: '2px 20px',
    fontSize: 14,
  },
};

export default function Poll({ poll, onClose, eventParticipant, isOffWall, isOffWallReady, isVenueParticipant }) {
  const [question, setQuestion] = useState(null);
  const [responses, setResponses] = useState([]);
  const [selection, setSelection] = useState('');
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);

  useEffect(() => {
    if (poll) {
      const parts = poll.data.split('\n').filter((d) => !!d);
      const _responses = [];

      parts.forEach((p, i) => {
        if (i === 0) {
          setQuestion(p);
        } else {
          _responses.push(p);
        }
      });
      setResponses(_responses);
    }
  }, [poll]);

  const _submitAnswer = async () => {
    try {
      setIsSubmittingAnswer(true);
      await post(`/poll/${poll._id}/answer`, { eventParticipant: eventParticipant._id, selection });
      setSelection('');
      onClose && onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmittingAnswer(false);
    }
  };

  const _close = async () => {
    try {
      setIsSubmittingAnswer(true);
      await post(`/poll/${poll._id}/answer`, { eventParticipant: eventParticipant._id, selection: null });
      setSelection('');
      onClose && onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmittingAnswer(false);
    }
  };

  const _renderResponses = () => {
    return responses.map((resp, i) => {
      const id = `poll-response-${i}`;
      let label;
      if (poll.prefixAnswersWith === 0) {
        label = resp;
      } else if (poll.prefixAnswersWith === 1) {
        label = `${String.fromCharCode(97 + i).toUpperCase()}. ${resp}`;
      } else if (poll.prefixAnswersWith === 2) {
        label = `${i + 1}. ${resp}`;
      }
      return (
        <li key={id} style={{ textAlign: 'left', listStyle: 'none' }}>
          <CustomRadio label={label} name='answer' value={resp} checked={selection === resp} onChange={({ target: { value } }) => setSelection(value)} />
        </li>
      );
    });
  };

  let canShowPoll = true;
  if (isOffWall) {
    canShowPoll = isOffWallReady;
  }
  return poll && canShowPoll ? (
    <div className='poll-container' style={{ top: isOffWall || isVenueParticipant ? 0 : 180 }}>
      <div className='custom-scrollbar' style={{ flex: 1, overflowY: 'auto', margin: '5px 0' }}>
        {question && <div style={styles.question}>{question}</div>}
        <ul style={styles.list}>{_renderResponses()}</ul>
      </div>
      <div>
        <Button text='Submit' type='primary' onClick={_submitAnswer} containerStyle={styles.button} disabled={isSubmittingAnswer || selection === ''} />
        <Button
          text='Close Without Responding'
          type='secondary'
          onClick={_close}
          containerStyle={{ ...styles.button, marginBottom: 0 }}
          disabled={isSubmittingAnswer}
        />
      </div>
    </div>
  ) : null;
}
