import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import List from '../../../common/List';
import Storage from '../../../utils/storage';
import ArrayUtil from '../../../utils/array-util';
import { get } from '../../../services/api';
import EventSupportAttendeeListItem from './EventSupportAttendeeListItem';
import EventSupportListHeader from './EventSupportListHeader';
import ParticipantCount from './ParticipantCount';
import './AttendeeList.css';

const localStorage = Storage.getLocalStorage();
let timerId;

export default function EventSupportAttendeeList({ attendees, onStartPrivateChat, show, event, hideAttendeeDetails }) {
  const offWallReactionStatus = useSelector((state) => state.app.offWallReactionStatus);
  const [sortedList, setSortedList] = useState([]);
  const [sortBy, setSortBy] = useState({ value: 'name', dir: 'desc' });

  const [offWallCount, setOffWallCount] = useState(0);
  const [observersCount, setObserversCount] = useState(0);
  const [onWallCount, setOnWallCount] = useState(0);

  const _sortList = (selectedSort) => {
    const sorted = ArrayUtil.sort(attendees, selectedSort);
    const index = sorted.findIndex((s) => s.uuid === uuid);
    if (index !== -1) {
      const u = sorted.splice(index, 1);
      sorted.unshift(u[0]);
    }
    setSortedList(sorted);
    setSortBy(selectedSort);
  };

  useEffect(() => {
    timerId = setInterval(async () => {
      const counts = await get(`/participant/counts?event=${event._id}`);
      if (counts.data) {
        setOnWallCount(counts.data.onWall);
        setOffWallCount(counts.data.offWall);
        setObserversCount(counts.data.observers);
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    _sortList(sortBy);
  }, [attendees]);

  const uuid = localStorage.getItem('uuid');

  return (
    <div className='event-support-attendee-list' style={{ display: show ? 'flex' : 'none' }}>
      <EventSupportListHeader
        columns={[
          { label: 'Name', value: 'name' },
          { label: 'Role', value: 'role' },
        ]}
        currentValue={sortBy}
        onSortChange={_sortList}
      />
      <List
        data={sortedList}
        renderItem={(item) => {
          let name = `${item.firstName} ${item.lastName}`.trim();
          if (hideAttendeeDetails) {
            name = item.firstName.trim();
          }
          return (
            <EventSupportAttendeeListItem
              id={item._id}
              name={name}
              role={item.role}
              reaction={uuid === item.uuid && offWallReactionStatus ? offWallReactionStatus : item.reactionStatus}
              status={item.status}
              uuid={item.uuid}
              highlight={uuid && uuid === item.uuid}
              onStartPrivateChat={() => onStartPrivateChat(item)}
            />
          );
        }}
        keyExtractor={(item) => `es-${item.uuid}`}
      />
      <div className='list-footer'>
        <ParticipantCount label='Off-Wall' value={offWallCount} />
        <ParticipantCount label='Observers' value={observersCount} />
        <ParticipantCount label='On-Wall' value={onWallCount} />
      </div>
    </div>
  );
}
