import React from 'react';
import ReactDOM from 'react-dom';
import swal from '@sweetalert/with-react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { loadScript, isIpad } from './utils/browser-util';
import UnsupportedDeviceMessage from './common/Messages/UnsupportedDevice';
import './css/index.css';
import './common/Messages/Messages.css';

const { REACT_APP_BACKEND_URL } = process.env;

(async () => {
  const { location, history } = window;
  const isSystemCheckRoute = location.pathname.endsWith('-system-check') === true;

  let studioId;
  const r = location.href.match(/\/studio\/([a-z0-9]+)\/login/);
  if (r && r.length > 1) {
    studioId = r[1];
  }
  if (studioId && studioId.length > 0) {
    location.replace(`${location.origin}/login${location.search}&studioId=${studioId}`);
    return;
  }
  const urlParams = new URLSearchParams(location.search);
  studioId = urlParams.get('studioId') || (history.state && history.state['studioId']);
  if (!studioId && !isSystemCheckRoute) {
    alert('Missing data.');
    return;
  }

  let studioTypeString;
  if (studioId) {
    const r = await fetch(`${REACT_APP_BACKEND_URL}/studio/${studioId}/studioTypeString`);
    studioTypeString = await r.text();
    sessionStorage.setItem('studioTypeString', studioTypeString);
    if (studioTypeString === 'Pando') {
      // Order is important, janus relies on jQuery
      await loadScript('/webrtc-adapter-8.1.0.min.js');
      await loadScript('/jquery-1.7.2.min.js');
      await loadScript('/janus.js');
    }
  }
  let isSupportedBrowser = true;

  const ClientUAInstance = new window.UAParser();
  const browser = ClientUAInstance.getBrowser();
  const device = ClientUAInstance.getDevice();

  if (browser.name === 'IE') {
    isSupportedBrowser = false;
  } else if (location.pathname.includes('venue-login')) {
    // don't show unsupported browser message for venue participants
  } else if (!isIpad && device.type != undefined) {
    // not a desktop
    if (studioTypeString === 'Pando') {
      alert('Pando is not supported on mobile devices except iPads. Please use a PC or an iPad.');
      return;
    }

    await swal({
      buttons: {},
      className: 'swal-custom-content',
      content: <UnsupportedDeviceMessage />,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  }

  if (isSupportedBrowser || studioTypeString === 'Broadcast' || isSystemCheckRoute) {
    ReactDOM.render(<App />, document.getElementById('root'));
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
