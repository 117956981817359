import React, { PureComponent } from 'react';
import uuidv4 from 'uuid/v4';
import RTCClient from '../../utils/rtc-client';
import SocketClient from '../../utils/socket-client';
import { WALL_PARTICIPANT } from '../../utils/user-util';
import { setStudioId, setToken, post } from '../../services/api';
import StringUtil from '../../utils/string-util';
import MediaDeviceUtil from '../../utils/media-device-util';
import BreakoutRoomTestClient from './BreakoutRoomTestClient';

export default class LoadTestClient extends PureComponent {
  state = {
    breakoutRoomsStarted: false,
  };

  _generateRandomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async componentDidMount() {
    this._urlParams = new URLSearchParams(window.location.search);
    const clear = this._urlParams.get('clear');
    if (!!clear) localStorage.clear();

    this._connect();
  }

  _connect = async () => {
    const socketServer = this._urlParams.get('socketServer');
    const apiServer = this._urlParams.get('apiServer');
    const eventId = this._urlParams.get('eventId');
    const studioId = this._urlParams.get('studioId');
    const runClock = this._urlParams.get('clock');

    setStudioId(studioId);

    const socketServerUrl = `https://${socketServer}.letspando.com`;

    this.uuid = this.uuid || localStorage.getItem('uuid') || uuidv4();
    this.name = this.name || `anim-${this._generateRandomString(3)}`;
    this.location = this.location || this._generateRandomString(10);

    const eventData = { _id: eventId };

    localStorage.setItem('participantEvent', JSON.stringify(eventData));

    const getUuidResponse = await new Promise((resolve) => {
      fetch(`https://${apiServer}.letspando.com/api/participant/getUuid`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'x-pando-studio-id': studioId,
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          pandoV1UNID: `LOAD_TEST_CLIENT_${this.name.toUpperCase()}`,
          eventId: eventData._id,
          firstName: this.name,
          lastName: '',
          phone: '999',
          location: this.location,
          role: WALL_PARTICIPANT,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        });
    });
    const { uuid, token } = getUuidResponse;
    this.uuid = uuid;
    localStorage.setItem('uuid', this.uuid);
    setToken(token);

    const connectData = {
      uuid: this.uuid,
      name: this.name,
      email: `${this.name}@loadtest.com`,
      location: this.location,
      event: eventData,
      isLoadTestClient: true,
      width: '1280',
      height: '720',
      socketServerUrl,
      role: WALL_PARTICIPANT,
      supportedResolutions: MediaDeviceUtil.defaultResolutions,
      platform: { osName: 'Mac OS', osVersion: '10+', browserName: 'Chrome', browserVersion: '91.0.4472.101' },
      availableDevices: [
        {
          deviceId: 'default',
          groupId: '797fef9286bffb0fef69cc63bde4ce53881d508c5401e13d96765aff5db8ea7a',
          kind: 'audioinput',
          label: 'Default - External Microphone (Built-in)',
        },
        {
          deviceId: '73204e56fa53c6bcce14372317b06c7b0234b22c79fa7ea448e92767f6453ede',
          groupId: '797fef9286bffb0fef69cc63bde4ce53881d508c5401e13d96765aff5db8ea7a',
          kind: 'audioinput',
          label: 'External Microphone (Built-in)',
        },
        {
          deviceId: 'fdf15851dfaf93ff9ae6ae42398017df3b6feacf8fd3c158a5ab26b3eb51d1cb',
          groupId: '71a69f7ec2874153a70928d1cdf0e64d4ccb71b8b2d28bccd61bd07451e959e3',
          kind: 'audioinput',
          label: 'USB Audio CODEC  (08bb:2902)',
        },
        {
          deviceId: '78dd7e65889b529ba4d5de2a4a2f60e591b21faf4536be884fe90c33b69130c2',
          groupId: 'f054fa9488c46a52677963a79fb1c38194856aeeed23b2e1a186b2d0b52cc94f',
          kind: 'audioinput',
          label: 'GENERAL WEBCAM (1b3f:2247)',
        },
        {
          deviceId: 'a5ffa7f34cbe2535809c88cb75f40ae814cedb4c11f10baf8107c707009490a4',
          groupId: '69c55e1ea29ab8551e1be5199d9a0b5b64a6f61e4c7c9fed0a0d8dcebef4ce78',
          kind: 'audioinput',
          label: 'Boom2Device',
        },
        {
          deviceId: 'b0ee870e548ad1668b8c00750f506c670488255b5bfc4326a11fbbb83aead863',
          groupId: 'f054fa9488c46a52677963a79fb1c38194856aeeed23b2e1a186b2d0b52cc94f',
          kind: 'videoinput',
          label: 'GENERAL WEBCAM (1b3f:2247)',
        },
        {
          deviceId: 'ebc5f47ef14c23690e304f0eec9dbb3c38ebcabe47ae5defb3ffe61f24c0e67e',
          groupId: '72ff080ca8994266c5d27fa78e503adb2297cbbdc59fe168aecbe49b7c80ffe3',
          kind: 'videoinput',
          label: 'FaceTime HD Camera',
        },
        {
          deviceId: 'default',
          groupId: '797fef9286bffb0fef69cc63bde4ce53881d508c5401e13d96765aff5db8ea7a',
          kind: 'audiooutput',
          label: 'Default - Headphones (Built-in)',
        },
        {
          deviceId: '9e1f5f3b8eaece63e8eb28d97da817a6d13708c65b23800d70ebd318f6609fc7',
          groupId: '797fef9286bffb0fef69cc63bde4ce53881d508c5401e13d96765aff5db8ea7a',
          kind: 'audiooutput',
          label: 'Headphones (Built-in)',
        },
        {
          deviceId: '47037f188912020effb35fe9a3f27d1fea52a8c3c8840803382faa8109dd287e',
          groupId: '71a69f7ec2874153a70928d1cdf0e64d4ccb71b8b2d28bccd61bd07451e959e3',
          kind: 'audiooutput',
          label: 'USB Audio CODEC  (08bb:2902)',
        },
        {
          deviceId: 'a5ffa7f34cbe2535809c88cb75f40ae814cedb4c11f10baf8107c707009490a4',
          groupId: '69c55e1ea29ab8551e1be5199d9a0b5b64a6f61e4c7c9fed0a0d8dcebef4ce78',
          kind: 'audiooutput',
          label: 'Boom2Device',
        },
      ],
      audioInput: {
        deviceId: '73204e56fa53c6bcce14372317b06c7b0234b22c79fa7ea448e92767f6453ede',
        groupId: '797fef9286bffb0fef69cc63bde4ce53881d508c5401e13d96765aff5db8ea7a',
        kind: 'audioinput',
        label: 'External Microphone (Built-in)',
      },
      videoInput: {
        deviceId: 'b0ee870e548ad1668b8c00750f506c670488255b5bfc4326a11fbbb83aead863',
        groupId: 'f054fa9488c46a52677963a79fb1c38194856aeeed23b2e1a186b2d0b52cc94f',
        kind: 'videoinput',
        label: 'GENERAL WEBCAM (1b3f:2247)',
      },
      audioOutput: {
        deviceId: '9e1f5f3b8eaece63e8eb28d97da817a6d13708c65b23800d70ebd318f6609fc7',
        groupId: '75acd5bcbefded3d361c20dfbb359709e7e67e04f358eccd895ca398f9e00108',
        kind: 'audiooutput',
        label: 'Headphones (Built-in)',
      },
    };

    const connectResult = await new Promise((resolve) => {
      fetch(`https://${apiServer}.letspando.com/api/participant/connect`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'x-pando-studio-id': studioId,
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(connectData),
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        });
    });

    if (runClock) {
      this._runClock();
    } else {
      this._runAnimation();
    }
    if (!SocketClient.socket || !SocketClient.socket.connected) {
      await SocketClient.setup(socketServerUrl, this.uuid, eventId, studioId, token);
    }

    SocketClient.joinRoom(this.uuid);
    SocketClient.joinRoom(`event-${eventId}`);

    SocketClient.addListener('breakout-start', ({ token }) => {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      rtcClient.disconnect();
      this.setState({ breakoutRoomsStarted: true });
    });

    SocketClient.addListener('load-test-client-action', async ({ action }) => {
      const r = Math.random() * 5000;
      await new Promise((resolve) => setTimeout(resolve, r));

      if (action === 'send-chat-message') {
        await post('/chatMessage', { event: eventId, fromParticipant: this.uuid, sendTo: 'ADMINS', message: StringUtil.generateRandomString(100) });
      } else if (action === 'give-reaction') {
        const reactions = ['RAISE_HAND', 'THUMBS_UP', 'THUMBS_DOWN'];
        const reaction = reactions[Math.floor(Math.random() * reactions.length)];
        console.log(`Sending reaction: ${reaction}`);
        if (reaction === 'RAISE_HAND') {
          SocketClient.emitClientAction('set-bg-color', { bgColor: '#1163e9' });
        } else if (reaction === 'THUMBS_UP') {
          SocketClient.emitClientAction('set-bg-color', { bgColor: '#277635' });
        } else if (reaction === 'THUMBS_DOWN') {
          SocketClient.emitClientAction('set-bg-color', { bgColor: '#BA1B2B' });
        }
        await post('/participant/reaction', { event: eventId, uuid: this.uuid, reaction });
      }
    });

    SocketClient.addListener('clear-all-audience-responses', async () => {
      console.log('clear-all-audience-responses');
      SocketClient.emitClientAction('set-bg-color', { bgColor: 'rgba(0,0,0,0.8)' });
      await post('/participant/reaction', { event: eventId, uuid: this.uuid, reaction: null });
    });

    SocketClient.addListener('clear-single-audience-response', async () => {
      console.log('clear-single-audience-response');
      SocketClient.emitClientAction('set-bg-color', { bgColor: 'rgba(0,0,0,0.8)' });
      await post('/participant/reaction', { event: eventId, uuid: this.uuid, reaction: null });
    });

    const rtcClient = new RTCClient();

    const canvas = document.querySelector('canvas');
    const stream = canvas.captureStream(30); // 30 fps
    const intervalId = setInterval(async () => {
      if (stream.getTracks().length !== 0) {
        rtcClient.connect({ stream, name: this.name, location: this.location, uuid: this.uuid, eventParticipantId: connectResult._id });
        clearInterval(intervalId);
      }
    }, 250);
  };

  _runClock = () => {
    const canvas = document.querySelector('canvas');

    function renderClock() {
      const d = new Date();
      const str = getClockString(d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());

      const context = canvas.getContext('2d');
      context.clearRect(0, 0, 1280, 720);
      context.font = '70pt calibri';
      context.fillStyle = 'white';
      context.fillText(str, 350, 350);
    }

    function getClockString(hour, min, sec, ms) {
      let curTime;
      if (hour < 10) curTime = '0' + hour.toString();
      else curTime = hour.toString();

      if (min < 10) curTime += ':0' + min.toString();
      else curTime += ':' + min.toString();

      if (sec < 10) curTime += ':0' + sec.toString();
      else curTime += ':' + sec.toString();

      if (ms < 10) curTime += ':00' + ms.toString();
      else if (ms < 100) curTime += ':0' + ms.toString();
      else curTime += ':' + ms.toString();
      return curTime;
    }

    this.timerId = setInterval(renderClock, 10);
  };

  _runAnimation = () => {
    const canvas = document.querySelector('canvas');
    const ctx = canvas.getContext('2d');

    let count = 0;
    let imgIndex = 1;
    this.timerId = setInterval(() => {
      ctx.globalAlpha = 0.01;
      const img = new Image(); // Create new img element
      img.onload = function () {
        ctx.drawImage(img, 0, 0, 1280, 720);
      };
      img.src = `https://pando-public.s3-us-west-1.amazonaws.com/sample-images/${imgIndex}.jpg`;

      if (++count === 100) {
        count = 0;
        imgIndex++;
        if (imgIndex > 7) {
          imgIndex = 1;
        }
      }
    }, 1000 / 30);
  };

  render() {
    const { breakoutRoomsStarted } = this.state;
    if (!breakoutRoomsStarted) {
      return <canvas style={{ position: 'absolute' }} width='1280' height='720' />;
    } else {
      return (
        <BreakoutRoomTestClient
          onBreakoutRoomStop={() => {
            this.setState({ breakoutRoomsStarted: false }, () => {
              this._connect();
            });
          }}
        />
      );
    }
  }
}
