import React, { useState, useEffect, useRef } from 'react';
import swal from '@sweetalert/with-react';
import Button from '../../common/Button';
import { push } from 'connected-react-router';
import { store } from '../../store';
import { put } from '../../services/api';
import { isIpad } from '../../utils/browser-util';
import MediaDeviceUtil from '../../utils/media-device-util';
import PoweredByPando_white from '../../assets/PoweredByPando_white.png';
import useStepNavigation from '../../hooks/useStepNavigation';
import OffWallParticipantForm from '../../common/OffWallParticipantForm';
import StepsNav from './StepsNav';
import BrowserCheck from './BrowserCheck';
import BrowserPermissionsCheck from './BrowserPermissionsCheck';
import VideoCheck from './VideoCheck';
import AudioCheck from './AudioCheck';
import BandwidthTest from './BandwidthTest';
import SuccessMessage from './SuccessMessage';
import FailureMessage from './FailureMessage';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { WALL_PRESENTER } from '../../utils/user-util';
import Storage from '../../utils/storage';
import SocketClient from '../../utils/socket-client';
import './Onboarding.css';

const localStorage = Storage.getLocalStorage();

const OnboardingComponents = [
  {
    title: 'Checking your Browser',
    component: (props) => <BrowserCheck {...props} />,
  },
  {
    title: 'Verifying Camera & Mic Access',
    component: (props) => <BrowserPermissionsCheck {...props} />,
  },
  {
    title: 'Checking your Connection Speed',
    component: (props) => <BandwidthTest {...props} />,
  },
  {
    title: 'Check your Camera',
    component: (props) => <VideoCheck {...props} />,
  },
  {
    title: 'Check your Microphone & Speakers',
    component: (props) => <AudioCheck {...props} />,
  },
];

export default function Onboarding({ notesData, loginLink, location }) {
  const participantInfoRef = useRef(null);

  const { steps, currentStep, setCurrentStep } = useStepNavigation(notesData.role === WALL_PRESENTER ? 3 : OnboardingComponents.length);
  const [enableHelpButton, setEnableHelpButton] = useState(false);
  const [systemCheckPassed, setSystemCheckPassed] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableHelpButton, setDisableHelpButton] = useState(false);

  useEffect(() => {
    const uuid = localStorage.getItem('uuid');
    SocketClient.joinRoom(uuid);
    SocketClient.addListener('change-input', async (payload) => {
      if (payload.type === 'participantInformation') {
        const { name } = payload.data;
        const nameParts = name.split(' ');
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(' ');
        participantInfoRef.current = { firstName, lastName };
      }
    });
    return () => {
      SocketClient.removeListener('change-input');
      SocketClient.leaveRoom(uuid);
    };
  }, []);

  const _getUpdatedParticipantInfo = async () => {
    MediaDeviceUtil.notesData = notesData;
    let participantInfo = { ...notesData };

    const storedAudioInput = localStorage.getItem('audioInput') ? JSON.parse(localStorage.getItem('audioInput')) : null;
    const storedAudioOutput = localStorage.getItem('audioOutput') ? JSON.parse(localStorage.getItem('audioOutput')) : null;
    const storedVideoInput = localStorage.getItem('videoInput') ? JSON.parse(localStorage.getItem('videoInput')) : null;

    const supportedResolutions = MediaDeviceUtil.defaultResolutions;
    const selectedResolution = supportedResolutions[0];
    const parts = selectedResolution.split('x');
    const selectedWidth = parseInt(parts[0]);
    const selectedHeight = parseInt(parts[1]);

    if (participantInfoRef.current) {
      const { firstName, lastName } = participantInfoRef.current;
      participantInfo.firstName = firstName;
      participantInfo.lastName = lastName;
    }

    participantInfo.selectedVideoInput = storedVideoInput;
    participantInfo.selectedAudioInput = storedAudioInput;
    participantInfo.selectedAudioOutput = storedAudioOutput;
    participantInfo.selectedWidth = selectedWidth;
    participantInfo.selectedHeight = selectedHeight;
    participantInfo.supportedResolutions = supportedResolutions;
    participantInfo.availableDevices = await MediaDeviceUtil.getAvailableDevices(false);
    return participantInfo;
  };

  const _navigateToConnectRoute = (participantInfo) => {
    MediaDeviceUtil.resetPromises();
    store.dispatch(push({ pathname: '/connect', state: participantInfo, search: location.search }));
  };

  const _sendToStudioQueue = async ({ city, state, phoneNumber }) => {
    if (notesData.role === WALL_PRESENTER) {
      _sendToScreening();
    } else {
      let participantInfo = await _getUpdatedParticipantInfo();
      if (city && state && phoneNumber) {
        participantInfo.location = `${city}, ${state}`;
        participantInfo.phone = phoneNumber;
      }
      const { eventParticipantId } = MediaDeviceUtil.notesData;
      await put(`/eventParticipant/${eventParticipantId}`, { screeningCompleted: true, inScreeningQueueSince: null });
      _navigateToConnectRoute(participantInfo);
    }
  };

  const _sendToScreening = async () => {
    try {
      let participantInfo = await _getUpdatedParticipantInfo();
      const { location, phone } = participantInfo;

      if (!location || !phone) {
        await swal({
          buttons: {},
          closeOnClickOutside: false,
          closeOnEsc: false,
          className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
          content: (
            <div className={'horizontal-message-box'} style={{ padding: 0, margin: 0, width: 'auto' }}>
              <div className='content' style={{ marginTop: 0, height: 'auto', background: 'none' }}>
                <div className='left' style={{ margin: 0 }}>
                  <OffWallParticipantForm
                    onAccept={({ city, state, phoneNumber }) => {
                      if (city && state && phoneNumber) {
                        participantInfo.location = `${city}, ${state}`;
                        participantInfo.phone = phoneNumber;
                      }
                      swal.close();
                    }}
                    participantData={participantInfo}
                    uiVersion='onboarding'
                  />
                </div>
              </div>
            </div>
          ),
        });
      }
      const { eventParticipantId } = MediaDeviceUtil.notesData;
      await put(`/eventParticipant/${eventParticipantId}`, { screeningCompleted: false, inScreeningQueueSince: new Date() });
      _navigateToConnectRoute(participantInfo);
    } catch (error) {
      console.error(error);
    }
  };

  const _updateProgress = async (data) => {
    try {
      const { eventParticipantId, systemCheck } = MediaDeviceUtil.notesData;
      MediaDeviceUtil.notesData.systemCheck = { ...systemCheck, ...data };
      await put(`/eventParticipant/${eventParticipantId}`, { systemCheck: data });
    } catch (error) {
      console.error(error);
    }
  };

  const _onHelpClick = async () => {
    try {
      setDisableHelpButton(true);
      localStorage.setItem('skipPhotoModal', 'true');

      const { eventParticipantId } = MediaDeviceUtil.notesData;
      await put(`/eventParticipant/${eventParticipantId}`, { helpRequested: true });

      _sendToScreening();
    } catch (error) {
      console.error(error);
    }
  };

  const _currentComponent = OnboardingComponents[currentStep];
  let _content = _currentComponent.component({
    title: _currentComponent.title,
    role: notesData.role,
    loginLink,
    onComplete: (valid) => {
      if (typeof valid !== 'undefined') {
        setEnableHelpButton(true);
      } else {
        setEnableHelpButton(false);
        setCurrentStep(currentStep + 1);
      }
    },
    onSuccess: () => {
      setSystemCheckPassed(1);
    },
    onFailure: (err) => {
      setErrorMessage(err);
      setEnableHelpButton(true);
      setSystemCheckPassed(-1);
    },
    onUpdateProgress: _updateProgress,
  });

  if (systemCheckPassed === 1) {
    _content = <SuccessMessage onContinue={_sendToStudioQueue} notesData={notesData} />;
  } else if (systemCheckPassed === -1) {
    _content = (
      <FailureMessage
        message={errorMessage}
        onRetry={() => {
          setCurrentStep(null);
          setCurrentStep(currentStep);
          setSystemCheckPassed(0);
        }}
      />
    );
  }

  return (
    <div className='onboarding-container'>
      <div className='onboarding'>
        <div className='onboarding-title'>
          <img src={PoweredByPando_white} alt='Pando Logo' width={135} />
        </div>
        <div className='onboarding-content'>{_content}</div>
        <div className='onboarding-footer'>
          {currentStep !== 0 && (
            <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
              <Button
                type='secondary'
                icon={faHeadset}
                iconType='FontAwesome'
                iconStyle={{ fontSize: 16, marginRight: 10 }}
                text={'Get Help'}
                onClick={_onHelpClick}
                disabled={disableHelpButton}
              />
            </div>
          )}
          {systemCheckPassed === 0 && <StepsNav steps={steps} currentStep={currentStep} onClick={setCurrentStep} />}
        </div>
      </div>
    </div>
  );
}
