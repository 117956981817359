import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import BrowserCheck from './BrowserCheck';
import { put } from '../../services/api';
import { store } from '../../store';
import PoweredByPando_white from '../../assets/PoweredByPando_white.png';
import './Onboarding.css';

export default function BrowserCheckPage(props) {
  const [loginLink, setLoginLink] = useState(null);

  useEffect(() => {
    const { origin, search } = window.location;
    setLoginLink(`${origin}/login${search}`);
  }, []);

  function onComplete(supported) {
    if (supported !== false) {
      const { state, search } = props.location;
      store.dispatch(push({ pathname: '/connect', state, search }));
    }
  }

  async function onUpdateProgress(data) {
    try {
      const { eventParticipantId } = props.location.state;
      await put(`/eventParticipant/${eventParticipantId}`, { systemCheck: data });
    } catch (error) {
      console.error(error);
    }
  }

  if (!loginLink) {
    return null;
  }

  return (
    <div className='onboarding-container'>
      <div className='onboarding'>
        <div className='onboarding-title'>
          <img src={PoweredByPando_white} alt='Pando Logo' width={135} />
        </div>
        <div className='onboarding-content'>
          <BrowserCheck title='Checking your Browser' skipAnimation={true} loginLink={loginLink} onComplete={onComplete} onUpdateProgress={onUpdateProgress} />
        </div>
      </div>
    </div>
  );
}
